import styled from 'styled-components'

import { BP_MEDIUM } from '../../styles/theme'

const CardContainer = styled.div`
  width: 50%;
  max-width: 450px;
  max-height: 350px;
  margin-right: 2rem;
  margin-bottom: 2rem;
  position: relative;
  padding: 1rem;
  overflow: hidden;
  background-color: ${props => props.theme.backgroundLight};
  box-shadow: var(--shadow-dark);

  @media only screen and (max-width: ${BP_MEDIUM}) {
    width: 95%;
  }
  &::before {
    content: '“';
    position: absolute;
    top: -3rem;
    left: -1.5rem;
    line-height: 1;
    font-size: 20rem;
    color: #f4f2f2;
    font-family: 'Lato';
    z-index: 1;
  }
`
const Content = styled.div`
  position: relative;
  z-index: 2;
  & > p {
    text-align: justify;
  }
  & > h4 {
    text-align: center;
  }
`
const Footer = styled.div`
  display: flex;
  justify-content: space-between;

  & > span {
    font-size: small;
    margin: 0 5px;
  }
`

export { CardContainer, Content, Footer }
