import React from 'react'
import _ from 'lodash'
import { navigate } from 'gatsby'

import Layout from '../components/Layout'
import Divider from '../components/common/Divider'
import Section from '../components/common/Section'
import Choosecards from '../components/ChooseCards'
import { SectionContainer, TitleCard } from '../components/common/Index.styled'
import SectionImagen from '../components/SectionVideo/Imagen'
import Button from '../components/common/Button.styled'
import ClientOpinions from '../components/ClientOpinions'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import { FormattedMessage } from 'react-intl'
import Solutions from '../components/Solutions'
import solutionsData from '../components/Solutions/utils'
import Logo from './../images/logo_as_internacional.png'

const ConstituirEmpresa = props => (
  <Layout
    title="Constituir Empresa"
    description="Con AS Consulting Group puedes constituir tu empresa en México en menos de 30 días."
    {...props}
  >
    <SectionImagen>
      <h1>
        <FormattedMessage id="company.header.title" />
      </h1>
      <img width={370} src={Logo} alt="logo" />
      <p>
        <FormattedMessage id="company.header.description" />
        <br />
        <br />
        <FormattedMessage id="company.header.parrafo" />
      </p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={() => navigate('/contacto/')}>
          <FormattedMessage id="element.button_register_free" />
        </Button>
      </div>
    </SectionImagen>
    <Divider>
      <h2>
        <FormattedMessage id="company.dividers.divider1.title" />
      </h2>
    </Divider>
    <Section>
      <Choosecards />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '10rem',
        }}
      >
        <Button onClick={() => navigate('/#consulting-services')}>
          <FormattedMessage id="element.button_services_known" />
        </Button>
      </div>
      <SectionContainer>
        <img
          src={require('../images/map.png')}
          height={300}
          alt="Todo el mundo"
        />
        <div style={{ maxWidth: '600px' }}>
          <TitleCard textAlign="left">
            <FormattedMessage id="company.section1.title" />
          </TitleCard>
          <Button onClick={() => navigate('/contacto/')}>
            <FormattedMessage id="element.button_register_free" />
          </Button>
          <p>
            <FormattedMessage id="company.section1.description" />
          </p>
        </div>
      </SectionContainer>
    </Section>
    <Section height="400px">
      <Solutions
        solutionsData={_.filter(solutionsData, ({ title }) =>
          _.includes(['llave', 'as_constitucion'], title)
        )}
      />
    </Section>
    <Section height="500px">
      <TitleCard textAlign="center">
        <FormattedMessage id="company.dividers.divider2.title" />
      </TitleCard>
      <ClientOpinions />
    </Section>
    <SectionHeaderImage
      image={require('../images/mexico_city.jpg')}
      position="0"
      large
    >
      <div
        style={{
          color: 'white',
          maxWidth: '900px',
        }}
      >
        <h2>
          <FormattedMessage id="company.footer.title" />
        </h2>
        <div style={{ fontSize: '1.2rem', fontWeight: '500' }}>
          <p>
            <FormattedMessage id="company.footer.description" />
          </p>
          <ul>
            {_.times(4, Number).map(i => (
              <li key={i}>
                <FormattedMessage id={`company.footer.list.item${i + 1}`} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </SectionHeaderImage>
  </Layout>
)

export default ConstituirEmpresa
