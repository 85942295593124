import React from 'react'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'

import { CardItem } from './ChooseCards.styled'
import Icon from '../common/Icon'

import { icons } from './utils'

const Choosecards = () => (
  <div
    style={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      paddingTop: '5rem',
    }}
  >
    {_.times(5, Number).map(i => (
      <CardItem key={i}>
        <Icon icon={icons[i]} size="5x" />
        <h3>
          <FormattedMessage id={`company.cards.card${i + 1}.title`} />
        </h3>
        <p>
          <FormattedMessage id={`company.cards.card${i + 1}.description`} />
        </p>
      </CardItem>
    ))}
  </div>
)

export default Choosecards
