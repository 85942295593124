import styled from 'styled-components'

const CardItem = styled.div`
  max-width: 350px;
  text-align: center;
  margin: 1rem 2rem;

  & > svg {
    color: ${props => props.theme.primary};
  }
  & > p {
    text-align: justify;
  }
`

export { CardItem }
