import React from 'react'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import Carousel from '../Carousel'

import { CardContainer, Content, Footer } from './CardPreview.styled'

const ClientOpinions = () => (
  <Carousel>
    {_.times(3, Number).map(i => (
      <CardContainer key={i}>
        <Content>
          <p>
            <FormattedMessage
              id={`company.opinions.card${i + 1}.description`}
            />
          </p>
          <h4>
            <FormattedMessage id={`company.opinions.card${i + 1}.author`} />
          </h4>
          <Footer>
            <FormattedMessage id={`company.opinions.card${i + 1}.company`} />
            <FormattedMessage id={`company.opinions.card${i + 1}.place`} />
          </Footer>
        </Content>
      </CardContainer>
    ))}
  </Carousel>
)

export default ClientOpinions
